<template>
  <div class="hero__content container-lg">
    <div class="hero__message">
      <h5 class="hero__heading" v-html="heading"></h5>
      <div class="hero__text" v-html="text"></div>
    </div>
    <div class="row row-cols-1 row-cols-md-3 gy-4 gx-5 hero__links">
      <div class="col" v-for="link in links" :key="link.index">
        <a
          v-if="$filters.isExternal(link.href)"
          class="hero__link"
          :href="link.href"
          >{{ link.title }}</a
        >
        <router-link v-else class="hero__link" :to="link.href">{{
          link.title
        }}</router-link>
        <div class="hero__link-description">
          <p>{{ link.description }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeroContent',
  components: {},
  props: {
    heading: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    links: {
      type: Array,
      required: true,
    },
  },
  computed: {},
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.hero {
  a {
    color: $white;
  }
  &__content {
    padding-top: 4rem;
    padding-bottom: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 1;
    @include media-breakpoint-up(md) {
      padding-top: 10rem;
      padding-bottom: 2rem;
    }
  }
  &__message {
    margin-bottom: 4rem;
    @include media-breakpoint-up(xl) {
      max-width: 60vw;
    }
  }
  &__heading {
    @include heading-base;
    font-size: 3rem;
    margin: 0 0 2rem;
    @include media-breakpoint-up(sm) {
      font-size: 5rem;
    }
  }
  &__text {
    font-family: $font-family-serif;
    font-size: $text-xl;
  }
  &__links {
    .hero__link {
      font-family: $font-family-block;
      @include heading-base;
      font-size: $header-md;
      margin-bottom: 1rem;
      display: inline-block;
      color: fade-out($white, 0);
      transition: color 100ms ease-in;
      &:after {
        content: '\232a';
        margin-left: 0.5em;
      }
      &:hover {
        text-decoration: none;
        color: $white;
      }
    }
  }
  &__link-description {
    font-family: $font-family-serif;
    font-size: $text-lg;
  }
}
</style>
